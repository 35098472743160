import React, { useEffect } from 'react';
import $ from 'jquery';
import 'jquery-modal';
import './Home.css';
import '../../styles/main.css';
import '../../assets/css/bootstrap/css/bootstrap.min.css'
import tokens from '../../assets/music/tokens.mp3'
import background_sound from '../../assets/music/niñas_malas.mp3'

import { FloatingWhatsApp } from 'react-floating-whatsapp-button'
import 'react-floating-whatsapp-button/dist/index.css'

function Home() {


  useEffect(() => {

    $('#Terms_Condition').modal();

    $('#Terms_Condition').modal({
      backdrop: 'static',
      keyboard: true,
      show: true
    });
    $("#Terms_Condition").modal('show');

    $('#my-modal-ok').on('click', function () {
      $.modal.close();
      var audio = document.getElementById("playAudio");
      audio.volume = "0.03"
      audio.play();
    });

    $('#my-modal-no').on('click', function () {
      window.location.href = 'https://mokitajar.com';
    });


  }, []);


  function unblockme() {
    var tokens = document.getElementById("tokensAudio");
    tokens.play();

    var play_audio = document.getElementById("playAudio");
    play_audio.pause();

    setTimeout(() => {
      window.location.href = 'https://ladiesroom.tv/models';
    }, 2000)
  }
  function loadPage() {
    console.log('xxxx')
    window.location.href = 'https://ladiesroom.tv';
  }

  return (

    <div>

      <div className='x'>
        <nav className="navbar navbar-expand-lg navbar-light bg-color-cuztom">
          <a className="navbar-brand" href="https://google.com"></a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item active">
                <a className="nav-link" href="https://www.youtube.com/@mokitajar">Como funciona <span className="sr-only">(current)</span></a>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link" href="https://google.com">Tutorial</a>
              </li> */}
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="https://google.com" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Más información
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <a className="dropdown-item" href="https://google.com">1</a>
                  <a className="dropdown-item" href="https://google.com">2</a>
                  <div className="dropdown-divider"></div>
                  <a className="dropdown-item" href="https://google.com">3</a>
                </div>
              </li>
            </ul>

          </div>
        </nav>
      </div>

      <div className='logo'>

        <div className="container">

          <div className="col-md-12">

            <div className="neon">
              Ladiesroom
            </div>
            {/* <div className='flux'>WEBCAM</div> */}

            {/* BUTTON STREAMATE */}
            <div className="menu">
              <div className="button" onClick={unblockme}> Conviertete en Modelo !</div>
            </div>


            {/* TOKEN AUDIO */}
            <audio id="tokensAudio">
              <source src={tokens} type="audio/mp3" />
              Your browser does not support the audio element.
            </audio>

            {/* MUSIC */}
            <audio loop id="playAudio">
              <source src={background_sound} type="audio/mp3" />
              Your browser does not support the audio element.
            </audio>

          </div>

          {/* MODAL TERMS AND CONDITON */}
          <div className="modal" role="dialog" id="Terms_Condition">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">

                </div>
                <div className="modal-body">
                  <h1 className="modal-title">Ladies Room</h1>
                  <p>
                    Es un Plataforma para Adultos .
                    Debes tener +18 para entrar
                    y estar de acuerdo con los terminos y condiciones del website  y sus servicios .
                  </p>
                </div>
                <div className="modal-footer">
                  <button type="button" onClick={loadPage()} id="my-modal-ok" className="btn btn-primary">Aceptar</button>
                  <button type="button" id="my-modal-no" className="btn btn-secondary">No</button>
                </div>
              </div>
            </div>
          </div>

        </div>

        {/* WHATSUP */}
        <FloatingWhatsApp
          zIndex="10"
          phone='+573232805247'
          popupMessage="click en registrar para iniciar el proceso. 🤫  Recuerda tener tu Cedula a la mano"
          accountName="DAVID"
        />
      </div>


    </div>

  );
}


export default Home;